/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from "vue";
import App from "../app.vue";
import SingleGalleryPage from "../SingleGallery";
import PrivacyPage from "../PrivacyPage";
import TermsPage from "../TermsPage";
import "../src/application.css";
import "./application.js";
import "./scripts.js";
// import Grid  from "./grid.js";
import WOW from "wow.js/dist/wow.js";

/**
 * Main App - Home page
 * example.com
 */
document.addEventListener("DOMContentLoaded", () => {
  // const el = document.body.appendChild(document.createElement('main'))
  const el = document.getElementById("mainApp"); // div element at app/views/pages/home

  /** Get data attributes for videos */
  let videoWebm = "";
  let videoMp4 = "";
  let videoJpg = "";
  let videoOgg = "";
  if (el) {
    videoWebm = el.getAttribute("data-video-webm");
    videoMp4 = el.getAttribute("data-video-mp4");
    videoOgg = el.getAttribute("data-video-ogg");
    videoJpg = el.getAttribute("data-video-jpg");
  }

  const app = new Vue({
    el,
    render: h =>
      h(App, {
        props: {
          videoWebm: videoWebm,
          videoJpg: videoJpg,
          videoMp4: videoMp4,
          videoOgg: videoOgg
        }
      })
  });

  /**
   * Single Gallery page
   * example.com/gallery/:id
   */
  const singleGallery = document.getElementById("sgallery"); // div element at app/views/pages/gallery
  if (singleGallery) {
    const sGallery = new Vue({
      singleGallery,
      render: h => h(SingleGalleryPage)
    }).$mount("#sgallery");
  }

  const privacy = document.getElementById("privacy"); // div element at app/views/pages/privacy
  if (privacy) {
    const privacyVue = new Vue({
      privacy,
      render: h => h(PrivacyPage)
    }).$mount("#privacy");
  }

  const terms = document.getElementById("terms"); // div element at app/views/pages/terms
  if (terms) {
    const termsVue = new Vue({
      terms,
      render: h => h(TermsPage)
    }).$mount("#terms");
  }

  const order = document.getElementById("order"); // div element at app/view/pages/terms
  if (order) {
    const orderVue = new Vue({
      order,
      render: h => h(OrderPage)
    }).$mount("#order");
  }

  //console.log({app})
  const wow = new WOW({
    boxClass: "wow",
    animateClass: "animated",
    offset: 0,
    live: false
  });
  wow.init();

  // Grid.init();
});

// var wow = new WOW(
//   {
//     boxClass:     'wow',      // animated element css class (default is wow)
//     animateClass: 'animated', // animation css class (default is animated)
//     offset:       0,          // distance to the element when triggering the animation (default is 0)
//     mobile:       true,       // trigger animations on mobile devices (default is true)
//     live:         true,       // act on asynchronously loaded content (default is true)
//     callback:     function(box) {
//       // the callback is fired every time an animation is started
//       // the argument that is passed in is the DOM node being animated
//     },
//     scrollContainer: null,    // optional scroll container selector, otherwise use window,
//     resetAnimation: true,     // reset animation on end (default is true)
//   }
// );
// wow.init();

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
