<template>
  <nav
    ref="navbar"
    v-bind:class="{ sticky: isSticky }"
    class="flex items-center justify-between flex-wrap bg-white p-6 absolute w-full z-10"
  >
    <div class="flex items-center flex-shrink-0 text-white mr-6 sm:w-64">
      <a id="logo" href="/">
        <img src="~images/logo.svg" class="logo" alt="Cakes in Ao Nang, Krabi" />
      </a>
    </div>
    <div class="block lg:hidden">
      <button
        id="nav-toggle"
        class="flex items-center px-3 py-2 border rounded text-brand-100 border-brand-100 hover:text-red-400 hover:border-red-400"
      >
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
    <div id="nav-content" class="w-full block flex hidden lg:flex lg:items-center lg:w-auto">
      <div class="text-lg lg:flex-grow uppercase ml-10">
        <a
          href="/#home"
          class="block mt-5 lg:inline-block lg:mt-0 text-black hover:text-white hover:text-brand-100 mr-4"
        >Home</a>
        <a
          href="/#about"
          class="block mt-5 lg:inline-block lg:mt-0 text-black hover:text-white hover:text-brand-100 mr-4"
        >About</a>
        <a
          href="/#work"
          class="block mt-5 lg:inline-block lg:mt-0 text-black hover:text-white hover:text-brand-100 mr-4"
        >Gallery</a>
        <a
          href="/#cakes"
          class="block mt-5 lg:inline-block lg:mt-0 text-black hover:text-white hover:text-brand-100 mr-4"
        >Cakes</a>
        <a
          href="/#prices"
          class="block mt-5 lg:inline-block lg:mt-0 text-black hover:text-white hover:text-brand-100 mr-4"
        >Price</a>
        <a
          href="/#contact"
          class="block mt-5 lg:inline-block lg:mt-0 text-black hover:text-white hover:text-brand-100 mr-4"
        >Contact</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {
      isSticky: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      //console.log("refs: ", this.$refs.navbar.offsetTop);
      if (window.pageYOffset >= this.$refs.navbar.offsetTop) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
#logo {
  padding-left: 0;
  padding-top: 0;
  img {
    width: 270px;
    height: 37px;
  }
}
nav {
  z-index: 10;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>