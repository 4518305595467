
//Javascript to toggle the menu
window.addEventListener('load', () => {
    /**
     * toggle menu button
     * on Button click
     */
    const nav = document.querySelector('#nav-toggle');
    if(nav) {
        nav.onclick = function(){
            document.getElementById("nav-content").classList.toggle("hidden");
        }
    }
    /**
     * toggle menu button
     * on Menu item click
     */
    const navItems = document.querySelectorAll('#nav-content div a');
    if(navItems) {
        for (const item of navItems) {
            item.addEventListener('click', function(event) {
                document.getElementById("nav-content").classList.toggle("hidden");
            })
          }
    }

    var onSubmit = function(response) {
        document.getElementById("contact_form").submit(); // send response to your backend service
    };

// var onLoad = function() {
//     widgetId = grecaptcha.render('componentId', {
//         'sitekey' : '6LdbGyQTAAAAAN_epki5I1KAOqPAdXsSW2Y7wX_r',
//         'callback' : onSubmit, 
//     });
// };


});

