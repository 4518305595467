<template>
  <div id="app">
    <header-view :message="message"></header-view>

    <div class="container first-container mx-auto">
      <div class="flex flex-wrap">
        <div class="md:w-1/2">
          <div v-if="image">
            <img :src="this.image.image_url" :alt="this.image.name" />
          </div>
        </div>
        <div class="md:w-1/2 right-column" v-if="image">
          <h1 class="text-3xl leading-none" v-text="this.image.name"></h1>
          <p v-text="this.showTags(this.image.tag_list)"></p>
        </div>
      </div>
    </div>
    <!-- .first-lcontainer -->
  </div>
</template>

<script>
import Header from "./components/Header";
import axios from "axios";
export default {
  name: "app",
  props: [],
  data() {
    return {
      message: "message",
      image: null
    };
  },
  computed: {},

  components: {
    "header-view": Header
  },

  mounted() {
    this.getDataForImage();
  },

  methods: {
    showTags(list) {
      // return list.split(',').join(" #")
      return list.join(" #");
    },
    getDataForImage() {
      let url = window.location.pathname;
      let strArr = url.split("/");
      let strLenght = strArr.length;
      let imageId = strArr[strLenght - 1];
      axios
        .get("/api/v1/gallery/" + imageId)
        .then(response => {
          if (response.status == 200) {
            this.image = response.data;
          }
        })
        .catch(error => console.error({ error }));
    }
  }
};
</script>

<style scoped lang="scss">
.first-container {
  padding-top: 140px;
}
.right-column {
  padding-left: 15px;
}
h1 {
  letter-spacing: 0px !important;
  line-height: 35px;
  padding-bottom: 15px;
  text-transform: capitalize;
}
</style>