<template>
  <div id="appPrivacy">
    <Header />
    <div class="container first-container mx-auto">
      <div class="flex flex-wrap">
        <div>
          <h1>Privacy Policy</h1>
          <h3>This policy covers how we use your personal information.</h3>
          <ul class="py-4 list-disc">
            <li>
              We take your privacy seriously and will take all measures to protect your
              personal information.
            </li>
            <li>
              Any personal information received will only be used to
              fill your order.
            </li>
            <li>We will not sell or redistribute your information to anyone.</li>
          </ul>
          <p class="text-xl">
            We use secure encrypted connection between you and server to protect your input
            data from other people.
          </p>
          <p class="text-xl">
            We don't keep any credit card information in our
            database, all data encrypts and goes to Secure Payment Gateway to charge for the
            goods.
          </p>

          <div class="py-4">
            <a href="/#contact" class="py-4 btn">Contact to order</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
export default {
  name: "appPrivacy",
  props: [],
  data() {
    return {};
  },
  components: { Header }
};
</script>

<style scoped lang="scss">
.first-container {
  padding-top: 140px;
  font-size: 18px;
  h1 {
    letter-spacing: 0px !important;
    line-height: 45px;
    padding-bottom: 15px;
    text-transform: capitalize;
    font-size: 43px;
    font-weight: 800;
  }
  h3 {
    letter-spacing: 0px !important;
    line-height: 35px;
    padding-bottom: 15px;
    text-transform: capitalize;
    font-size: 23px;
  }
}
.right-column {
  padding-left: 15px;
}
</style>