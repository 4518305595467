<template>
    <div id="app">

    <header-view :message="message"></header-view>

    <div id="home" class="bg-image" ref="bg_image">
        <div class="light-overlay">
            <div id="contents">

                <div id="content" ref="content" :style="{top: this.contentHeight}">
                    <div
                        class="wow bounceInDown animated"
                        data-wow-duration="2s"
                        data-wow-delay="3s"
                        style="visibility: visible; animation-duration: 2s; animation-delay: 3s; animation-name: bounceInDown;">
                    <div
                        class="heart wow pulse animated"
                        data-wow-iteration="infinite"
                        style="visibility: visible; animation-iteration-count: infinite; animation-name: pulse;">
                    </div>
                    </div>

                    <div
                        class="byline wow slideInLeft pad45 animated"
                        data-wow-delay="3s"
                        style="visibility: visible; animation-delay: 3s; animation-name: slideInLeft;">
                        Est. 2012
                    </div>

                    <h1
                        class="wow bounceInDown animated  text-5xl sm:text-6xl lg:text-8xl"
                        data-wow-duration="3s"
                        data-wow-delay="2s"
                        style="visibility: visible; animation-duration: 3s; animation-delay: 2s; animation-name: bounceInDown;">
                        Baking Sweet Memories
                    </h1>

                    <div
                        class="name wow bounceInDown animated"
                        data-wow-duration="2s"
                        data-wow-delay="1s"
                        style="visibility: visible; animation-duration: 2s; animation-delay: 1s; animation-name: bounceInDown;">
                        One Cake at a Time
                    </div>

                    <div class="text-center pad30">
                        <a href="#about" class="scroll">
                            <i class="fa fa-caret-down fa-inverse ilink fa-3x wow rotateIn animated"
                                data-wow-duration="1s"
                                data-wow-delay="4s"
                                style="visibility: visible; animation-duration: 1s; animation-delay: 4s; animation-name: rotateIn;">
                            </i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="text-center pad30">
                <a href="#about" class="scroll">
                    <i class="fa fa-caret-down fa-inverse ilink fa-3x wow rotateIn animated"
                        data-wow-duration="1s"
                        data-wow-delay="4s"
                        style="visibility: visible; animation-duration: 1s; animation-delay: 4s; animation-name: rotateIn;">
                    </i>
                </a>
            </div>
        </div>
    </div>

    <section id="big_image"></section>

    <section id="about">
        <div class="container mx-auto">
            <div class="p-4">
                <div class="w-full">
                    <!-- Desktop -->
                    <h2 
                        v-if="!isMobile"
                        class="wow fadeInRightBig animated text-8xl mb-8"
                        data-wow-offset="80"
                        data-wow-duration="2s"
                        style="visibility: visible; animation-duration: 2s; animation-name: fadeInRightBig;">
                        What I do
                    </h2>
                    <!-- Mobile -->
                    <h2 v-if="isMobile" class="text-5xl mb-8">
                        What I do
                    </h2>

                    <!-- Desktop -->
                    <div
                        v-if="!isMobile"
                        class="lead wow fadeInRightBig animated"
                        data-wow-offset="80"
                        data-wow-duration="2s"
                        style="visibility: visible; animation-duration: 2s; animation-name: fadeInRightBig;">
                            I do <strong>cakes</strong>, <strong>cupcakes</strong> and <strong>cookies</strong> are all baked fresh using the finest ingredients to ensure that not only do they look good, but <span class="colour"><strong>taste good</strong></span> as well, so no matter what your occasion is, remember... <span class="colour"><strong>i make cakes</strong></span>
                    </div>
                    <!-- Mobile -->
                    <div v-if="isMobile" class="text-xl mb-4">
                        I do <strong>cakes</strong>, <strong>cupcakes</strong> and <strong>cookies</strong> are all baked fresh using the finest ingredients to ensure that not only do they look good, but <span class="colour"><strong>taste good</strong></span> as well, so no matter what your occasion is, remember... <span class="colour"><strong>i make cakes</strong></span>
                    </div>

                    <!-- Three columns -->
                    <div class="flex flex-wrap mb-4">

                        <!-- SERVICE 1 Desktop -->
                        <div v-if="!isMobile" class="w-full md:w-1/3 text-center wow fadeIn animated"
                            data-wow-offset="80"
                            data-wow-duration="2s"
                            data-wow-delay="1s"
                            style="visibility: visible; animation-duration: 2s; animation-delay: 1s; animation-name: fadeIn;">
                            <div class="service">
                                <div id="icon-cake" style="height:120px">
                                    <img width="80"
                                        alt="Birthday cakes"
                                        style="margin: auto auto; padding-top: 18px;"
                                        src="~/images/cake.png">
                                </div>
                                <!--<i class="fa fa-laptop"></i>-->
                            </div>
                            <h6>Cakes</h6>
                            <p class="text-base">
                                Making a birthday, wedding or celebration cake or just looking for a great shop to get all your home baking &amp; cake decorating supplies?
                            </p>
                        </div>
                        <!-- SERVICE 1 Mobile -->
                        <div v-if="isMobile" class="w-full md:w-1/3 text-center"
                            style="visibility: visible;">
                            <div class="service">
                                <div id="icon-cake" style="height:120px">
                                    <img width="80"
                                        alt="Birthday cakes"
                                        style="margin: auto auto; padding-top: 18px;"
                                        src="~/images/cake.png">
                                </div>
                                <!--<i class="fa fa-laptop"></i>-->
                            </div>
                            <h6 class="text-2xl">Cakes</h6>
                            <p class="text-lg">
                                Making a birthday, wedding or celebration cake or just looking for a great shop to get all your home baking &amp; cake decorating supplies?
                            </p>
                        </div>

                        <!-- SERVICE 2 Desktop -->
                        <div v-if="!isMobile" class="w-full md:w-1/3 text-center wow fadeIn animated"
                            data-wow-offset="80"
                            data-wow-duration="2s"
                            data-wow-delay="1.5s"
                            style="visibility: visible; animation-duration: 2s; animation-delay: 1.5s; animation-name: fadeIn;">
                            <div class="service">
                            <div id="icon-cake" style="height:120px">
                                <img width="80"
                                    alt="Bake cookies in Phuket"
                                    style="margin: auto auto; padding-top: 18px"
                                    src="~/images/cookies.png">
                            </div>
                            <!--<i class="fa fa-magic"></i>-->
                            </div>
                            <h6>Cookies</h6>
                            <p>
                                An assortment of cookies you just can’t resist. Peanut butter, double chocolate with white chips, M&amp;M, chocolate chip cookies.
                            </p>
                        </div>
                        <!-- SERVICE 2 Mobile -->
                        <div v-if="isMobile" class="w-full md:w-1/3 text-center"
                            style="visibility: visible;">
                            <div class="service">
                            <div id="icon-cake" style="height:120px">
                                <img width="80"
                                    alt="Bake cookies in Phuket"
                                    style="margin: auto auto; padding-top: 18px"
                                    src="~/images/cookies.png">
                            </div>
                            <!--<i class="fa fa-magic"></i>-->
                            </div>
                            <h6 class="text-2xl">Cookies</h6>
                            <p class="text-lg">
                                An assortment of cookies you just can’t resist. Peanut butter, double chocolate with white chips, M&amp;M, chocolate chip cookies.
                            </p>
                        </div>

                        <!-- SERVICE 3 Desktop -->
                        <div v-if="!isMobile" class="w-full md:w-1/3 text-center  wow fadeIn animated"
                            data-wow-offset="80"
                            data-wow-duration="2s"
                            data-wow-delay="2s"
                            style="visibility: visible; animation-duration: 2s; animation-delay: 2s; animation-name: fadeIn;">
                            <div class="service">
                            <div id="icon-cake" style="height:120px">
                                <img width="80"
                                    alt="Cupcakes in Phuket"
                                    style="margin: auto auto; padding-top: 18px"
                                    src="~/images/cupcake.png">
                            </div>
                            <!--<i class="fa fa-tasks"></i>-->
                            </div>
                            <h6>Cupcakes</h6>
                            <p>
                                Bite sized cupcakes topped with any of our butter-cream frostings. Perfect for parties!
                            </p>
                        </div>
                        <!-- / SERVICE -->

                        <!-- SERVICE 3 Mobile -->
                        <div v-if="isMobile" class="w-full md:w-1/3 text-center"
                            style="visibility: visible;">
                            <div class="service">
                            <div id="icon-cake" style="height:120px">
                                <img width="80"
                                    alt="Cupcakes in Phuket"
                                    style="margin: auto auto; padding-top: 18px"
                                    src="~/images/cupcake.png">
                            </div>
                            <!--<i class="fa fa-tasks"></i>-->
                            </div>
                            <h6 class="text-2xl">Cupcakes</h6>
                            <p class="text-lg">
                                Bite sized cupcakes topped with any of our butter-cream frostings. Perfect for parties!
                            </p>
                        </div>
                        <!-- / SERVICE -->
                    </div><!-- flex -->

                </div>
            </div>
        </div>
    </section>


    <section id="work" class="py-20">
        <div class="container mx-auto p-4">
            <div class="w-full">
                <!-- Desktop -->
                <h3 v-if="!isMobile" class="wow fadeInRightBig animated text-8xl mb-8"
                    data-wow-offset="80"
                    data-wow-duration="2s"
                    style="visibility: visible; animation-duration: 2s; animation-name: fadeInRightBig;">
                    Cakes Gallery
                </h3>
                <!-- Mobile -->
                <h3 v-if="isMobile" class="text-5xl mb-8"
                    style="visibility: visible;">
                    Cakes Gallery
                </h3>

                <!-- Desktop -->
                <div v-if="!isMobile" class="lead wow fadeInRightBig animated"
                    data-wow-offset="80"
                    data-wow-duration="2s"
                    style="visibility: visible; animation-duration: 2s; animation-name: fadeInRightBig;">
                        Custom cakes can be <strong>decorated</strong> for any <strong>special occasion</strong>.<br>Please see <strong>gallery</strong> for a sample of <strong>decorated cakes</strong>.
                </div>
                <!-- Mobile -->
                <div v-if="isMobile" class="text-2xl"
                    style="visibility: visible;">
                        Custom cakes can be <strong>decorated</strong> for any <strong>special occasion</strong>.<br>Please see <strong>gallery</strong> for a sample of <strong>decorated cakes</strong>.
                </div>

                <div class="pad45"></div>

            <!--FILTERS-->
            <!--
            <div id="filters-container" class="cbp-l-filters-button">
            <button data-filter="*" class="cbp-filter-item-active cbp-filter-item">All<span class="cbp-filter-counter"></span></button>
            <button data-filter="." class="cbp-filter-item"><span class="cbp-filter-counter"></span></button>
            </div> -->

            <!--PORTFOLIO-->
            <gallery
                :isMobile="isMobile"
            ></gallery>

            <div class="pad45"></div>
        </div>
        <!--//END PORTFOLIO-->
    </div>

    <!--PARALLAX WORK QUOTE-->
    <div class="well">
        <div class="dark_overlay">
            <div class="container mx-auto">
                <div class="row">
                    <div class="col-sm-12 col-lg-10 col-lg-offset-1 text-center">
                        <h2 v-if="!isMobile" class="fadeInUp text-center quote">
                            I offering <strong>home-style</strong> dessert favorites baked entirely from scratch using the freshest &amp; finest ingredients.
                        </h2>
                        <h2 v-if="isMobile" class="p-4 fadeInUp text-center quote">
                            I offering <strong>home-style</strong> dessert favorites baked entirely from scratch using the freshest &amp; finest ingredients.
                        </h2>
                        <a v-if="!isMobile" href="#contact" class="scroll btn wow bounceIn" data-wow-offset="80" data-wow-duration="2s" style="visibility: hidden; animation-duration: 2s; animation-name: none;">Contact</a>
                        <a v-if="isMobile" href="#contact" class="scroll btn" style="visibility: visible">Contact</a>
                        <div class="pad90"></div>	
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--//PARALLAX WORK QUOTE ENDS-->
    </section>

    <section id="cakes">
        <div v-if="!isMobile" class="pad45"></div>
        <div class="container mx-auto px-4">
            <div class="w-full">
                <h3 v-if="!isMobile" class="wow fadeInRightBig animated text-8xl mb-8"
                    data-wow-offset="80"
                    data-wow-duration="2s"
                    style="visibility: visible; animation-duration: 2s; animation-name: fadeInRightBig;">
                    Cake list
                </h3>
                <h3 v-if="isMobile" class="text-5xl mb-8"
                    style="visibility: visible;">
                    Cake list
                </h3>
                <ul class="cakes" v-if="this.cakes">
                    <li v-for="cake in this.cakes" :key="cake.id">
                        <h3><strong v-text="cake.title"></strong></h3>
                        <p v-text="cake.description"></p>
                        <button class="btn" @click.prevent="makeOrder()" :data-id="cake.id">Order {{cake.button_title}}</button>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <section id="prices">
        <div class="pad45"></div>
        <div class="container mx-auto px-4">
            <div class="w-full">
                <h3 v-if="!isMobile" class="wow fadeInRightBig animated text-8xl mb-8"
                    data-wow-offset="80"
                    data-wow-duration="1s"
                    style="visibility: visible; animation-duration: 1s; animation-name: fadeInRightBig;">
                    Prices
                </h3>
                <h3 v-if="isMobile" class="text-5xl mb-8"
                    style="visibility: visible;">
                    Prices
                </h3>

                <ul class="listing">
                    <li class="text-2xl sm:text-2xl md:text-2xl lg:text-4xl xl:text-4xl py-3 leading-tight">
                        If you're planning an <strong>event</strong> and want something above and beyond what <strong>you see</strong> on my site, <strong>I can create</strong> it!
                    </li>
                    <li class="text-2xl sm:text-2xl md:text-2xl lg:text-4xl xl:text-4xl py-3 leading-tight">
                        <strong>Western</strong> style <strong>cakes</strong> in Krabi, all cakes are made of high <strong>quality</strong> ingredients, most of them are imported to Thailand to achieve <strong>European taste</strong>
                    </li>
                    <li class="text-2xl sm:text-2xl md:text-2xl lg:text-4xl xl:text-4xl py-3 leading-tight">
                        48 Hour notice for all buttercream cakes
                    </li>
                    <li class="text-2xl sm:text-2xl md:text-2xl lg:text-4xl xl:text-4xl py-3 leading-tight">
                        1 week notice for all fondant cakes
                    </li>
                </ul>

                <div class="pad45"></div>
                <div class="flex flex-wrap">
                <!-- Price Block one -->
                    <div class="flex lg:w-1/2 mx-auto my-5">
                        <div class="max-w-sm mx-auto rounded overflow-hidden shadow-lg">
                            <div class="font-bold text-xl mb-2 text-center bg-gray-800 px-6 py-4 text-white">Cakes</div>
                            <div class="px-6 py-4">
                                <ul class="price-list">
                                    <!--
                                    <li><strong>15cm (servers 4-6)</strong> - 900 THB</li>
                                    <li><strong>18cm (servers 6-8)</strong> - 1000 THB</li>
                                    <li><strong>20cm (servers 10-12)</strong> - 2000 THB</li>
                                    <li><strong>23cm (servers 14-16)</strong> - 3000 THB</li>
                                    <li><strong>25cm (servers 18-22)</strong> - 4000 THB</li>
                                    -->
                                </ul>
                            </div>
                            <div class="px-6 py-4 text-center">
                                <button
                                    @click.prevent="makeOrder()"
                                    class="btn">Order</button>
                            </div>
                        </div>
                    </div>
                <!-- Price Block two -->
                    <div class="flex lg:w-1/2 mx-auto my-5">
                        <div class="max-w-sm mx-auto rounded overflow-hidden shadow-lg">
                            <div class="font-bold text-xl mb-2 text-center bg-gray-800 text-white px-6 py-4">Cupcakes</div>
                            <div class="px-6 py-4">
                                <ul class="price-list">
                                    <!--
                                    <li><strong>Icing/sprinkles</strong> 80 THB/ea</li>
                                    <li><strong>Icing w/custom toppers</strong> 130 THB/ea</li>
                                    -->
                                </ul>
                            </div>
                            <div class="px-6 py-4 text-center">
                                <button
                                @click.prevent="makeOrder()"
                                class="btn">Order</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Price blocks -->
                <div class="pad45"></div>

                <ul v-if="!isMobile">
                    <li
                        v-for="item in extraListing"
                        :key="item.id"
                        style="border-bottom:1px dashed lightgray;"
                        class="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl py-2 leading-tight">
                        <span>{{item.text}}</span> <span style="white-space: nowrap;">{{item.price == 0 ? '' : '+'+item.price}} {{item.tag}}</span>
                    </li>
                </ul>
                <ul class="extra-price-list" v-if="isMobile">
                    <li
                        v-for="item in extraListing"
                        :key="item.id"
                        class="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl py-2 leading-tight">
                        <span>{{item.text}}</span> <span style="white-space: nowrap;">{{item.price == 0 ? '' : '+'+item.price}} {{item.tag}}</span>
                    </li>
                </ul>
                <div class="text-xl sm:text-xl md:text-2xl lg:text-3xl py-8 text-left">
                    Please contact me if you want baloons or flowers for your birthday, aniversary or any event party.
                </div>

                <button
                    @click.prevent="makeOrder()"
                    class="btn">contact</button>

            </div>
        </div>
    </section>


    <section id="prices">
        <div v-if="!isMobile" class="pad45"></div>
        <div class="container mx-auto px-4">
            <div class="w-full">
                <h3 v-if="!isMobile" class="wow fadeInRightBig animated text-8xl mb-8"
                    data-wow-offset="80"
                    data-wow-duration="1s"
                    style="visibility: visible; animation-duration: 1s; animation-name: fadeInRightBig;">
                    Delivery
                </h3>
                <h3 v-if="isMobile" class="text-5xl mb-8"
                    style="visibility: visible;">
                    Delivery
                </h3>

                <ul class="delivery-list">
                    <li class="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl py-2 leading-tight">Delivary time: 7AM - 8PM</li>
                    <li
                        v-for="item in this.deliveryAreaList" :key="item.id"
                        class="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl py-2 leading-tight">
                        <span v-text="item.text"></span> <span>= {{item.price}} {{item.tag}}</span>
                    </li>
                </ul>
                <button
                    @click.prevent="makeOrder()"
                    class="btn">
                    Contact to order
                    </button>
                <div class="pad45"></div>
            </div>
        </div>
    </section>

    <section class="video-player">
        <div class="video-container">
            <video autoplay muted loop id="myVideo" :poster="this.videoJpg">
                <source :src="this.videoMp4" type="video/mp4">
            </video>
            <div class="overlay">
                <div>
                <h2
                    class="text-xl sm:text-xl md:text-2xl lg:text-4xl leading-snug px-8">
                    Cakes are special. Every birthday, every celebration ends with something sweet, a cake, and people remember. It's all about the memories.
                </h2>
                <h4
                    class="text-base sm:text-sm md:text-sm lg:text-lg leading-none md:leading-tight lg:leading-snug py-4">
                    Buddy Valastro
                </h4>
                </div>
            </div>
        </div>
    </section>

    <section id="contact">
        <div class="container mx-auto px-4">
            <div class="w-full">
                <h3 v-if="!isMobile" class="text-white wow fadeInRightBig animated text-8xl mb-8"
                    data-wow-offset="80"
                    data-wow-duration="1s"
                    style="visibility: visible; animation-duration: 1s; animation-name: fadeInRightBig;">
                    Contact
                </h3>
                <h3 v-if="isMobile" class="text-5xl mb-8 text-white"
                    style="visibility: visible;">
                    Contact
                </h3>
                <h4 class="text-white mb-8 leading-snug text-xl sm:text-xl md:text-2xl lg:text-4xl">
                    Sometimes <strong>magic</strong> is just someone <strong>spending</strong> more time on something than anyone else might reasonably expect.
                </h4>
            </div>
            <div class="flex flex-wrap">
                <!-- Left contact block -->
                <div class="flex lg:w-1/2 mx-auto my-5">
                    <div class="text-left">
                        <h4 class="text-left text-white mb-8 leading-snug text-xl sm:text-2xl md:text-3xl lg:text-3xl">
                            Do you need a cake, have a question or comment?
                        </h4>
                        <p class="text-left text-xl">Rita<br>Ao Nang, Krabi 81180</p>
                        <ul class="contact-details py-8">
                            <li class="text-xl py-2">
                                <img src="~images/mail-black-envelope-symbol.svg" width="24px;" alt="imagecakes contacts in krabi ao nang, thailand"/>
                                <a href="mailto:imakecakescafe@gmail.com">imakecakescafe@gmail.com</a>
                            </li>
                            <li class="text-xl py-2">
                                <img src="~images/world.svg" width="24px" alt="Imakecakes contacts in krabi ao nang"/>
                                <a href="https://www.imakecakes.cafe" target="_self">www.imakecakes.cafe</a>
                            </li>
                        </ul>
                        <ul class="social-media">
                            <li>
                                <a href="https://www.facebook.com/imakecakescafe/" target="_blank">
                                <img src="~images/facebook-logo.svg" width="50px" alt="facebook imakecakes cakes in krabi" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/imakecakes.cafe/" target="_blank">
                                <img src="~images/instagram-social-network-logo-of-photo-camera.svg" width="50px" alt="instagram iamakecakes in ao nang, krabi" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Right contact block -->
                <div class="flex lg:w-1/2 w-full mx-auto my-5">
                    <form v-if="!contactFormSent" id="contact_form" class="w-full px-8 pt-6 pb-8 mb-4">
                        <div class="mb-2">
                            <label class="text-white block text-sm font-bold mb-2" for="name">
                                Name <span v-if="this.nameError" v-text="this.nameError" class="text-blue-900"></span>
                            </label>
                            <input
                                class="appearance-none rounded w-full py-3 px-3 text-white bg-red-400 leading-tight focus:outline-none focus:shadow-outline" id="name"
                                v-model="contactName"
                                @input="validateForm"
                                type="text"
                                autocomplete="off"
                                placeholder="Name">
                        </div>
                        <div class="mb-2">
                            <label class="text-white block text-sm font-bold mb-2" for="email">
                                Email <span v-if="this.emailError" v-text="this.emailError" class="text-blue-900"></span>
                            </label>
                            <input
                                v-model="contactEmail"
                                @input="validateForm"
                                class="appearance-none rounded w-full py-3 px-3 text-white bg-red-400 leading-tight focus:outline-none focus:shadow-outline" id="email"
                                type="text"
                                placeholder="Email">
                        </div>
                        <div class="mb-2">
                            <label class="text-white block text-sm font-bold mb-2" for="subject">
                                Subject
                            </label>
                            <input
                                v-model="contactSubject"
                                class="appearance-none rounded w-full py-3 px-3 text-white bg-red-400 leading-tight focus:outline-none focus:shadow-outline" id="subject"
                                type="text"
                                autocomplete="off"
                                placeholder="Subject">
                        </div>
                        <div class="mb-2">
                            <label class="text-white block text-sm font-bold mb-2" for="message">
                                Message <span v-if="this.messageError" v-text="this.messageError" class="text-blue-900"></span>
                            </label>
                            <textarea
                                v-model="contactMessage"
                                @input="validateForm"
                                class="h-40 appearance-none rounded w-full py-3 px-3 text-white bg-red-400 leading-tight focus:outline-none focus:shadow-outline" id="subject"
                                type="text"
                                autocomplete="off"
                                placeholder="Message"></textarea>
                        </div>
                        <div class="g-recaptcha" data-sitekey="6LdbGyQTAAAAAN_epki5I1KAOqPAdXsSW2Y7wX_r"></div>
                        <div class="flex flex-col">
                            <span v-if="this.nameError" v-text="this.nameError" class="text-white"></span>
                            <span v-if="this.emailError" v-text="this.emailError" class="text-white"></span>
                            <span v-if="this.messageError" v-text="this.messageError" class="text-white"></span>
                        </div>
                        <div class="mb-2">
                            <input
                                @click.prevent="sendContactForm()"
                                type="submit"
                                class="bg-gray-800 px-8 py-5 cursor-pointer"
                                value="SUBMIT">
                        </div> 
                        <!-- <button class="g-recaptcha" data-sitekey="6LdbGyQTAAAAAN_epki5I1KAOqPAdXsSW2Y7wX_r" data-callback="onSubmit">Submit recaptcha</button> -->
                    </form>
                    <div v-if="contactFormSent" style="border: 1px solid #ffa39e;">
                        <h3  class="text-center text-white text-xl sm:text-xl md:text-2xl lg:text-4xl p-8">Thank you!</h3>
                        <p class="text-center text-white text-xl sm:text-xl md:text-xl lg:text-2xl p-8">Your message has been sent, I will reply as soon as possible.</p>
                        <p class="text-center">
                            <button
                                @click="sendNewMessage"
                                class="btn gb-gray-800 px-8 py-5 cursor-pointer"
                                style="background: #2E3138!important">
                                Send new message
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </section>


    <footer>
        <div class="center footer">
        <!-- UP TO TOP -->
            <div
                v-if="!isMobile"
                class="wow bounce animated"
                data-wow-offset="80"
                data-wow-duration="2s"
                style="visibility: visible; animation-duration: 2s; animation-name: bounce;">
                <a href="#home" class=" scroll">
                    <span class="fa-stack fa-lg">
                    <i class="fa fa-circle fa-stack-2x "></i>
                    <i class="fa fa-angle-double-up fa-stack-1x fa-inverse"></i>
                    </span>
                </a>
            </div>
            <div v-if="isMobile">
                <a href="#home" class=" scroll">
                    <span class="fa-stack fa-lg">
                    <i class="fa fa-circle fa-stack-2x "></i>
                    <i class="fa fa-angle-double-up fa-stack-1x fa-inverse"></i>
                    </span>
                </a>
            </div>

            <div v-if="!isMobile" id="copyright" class="wow bounceIn animated" data-wow-offset="80" data-wow-duration="2s" style="visibility: visible; animation-duration: 2s; animation-name: bounceIn;">© {{ curYear }} I Make Cakes
                <br>
                <a href="#contact">Make your occasion a day to remember. Since 2011</a>
                <br>
                <a href="/privacy">privacy policy</a>
                <br>
                <a href="/terms">terms</a>
            </div>
            <div v-if="isMobile" id="copyright" >© {{ curYear }} I Make Cakes
                <br>
                <a href="#contact">Make your occasion a day to remember. Since 2011</a>
                <br>
                <a href="/privacy">privacy policy</a>
                <br>
                <a href="/terms">terms</a>
            </div>
        </div>
    </footer>


  </div>
</template>

<script>
import Header from "./components/Header";
import axios from "axios";
// import Grid  from "./packs/grid.js";
import Gallery from './gallery';
const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

export default {
  name: "app",
  props: ['videoWebm','videoMp4','videoOgg','videoJpg'],
  data: function () {
    return {
        curYear: new Date().getFullYear(),
        message: "Hello Vue!",
        from: 0,
        take: 12,
        data_image_count: 12,
        contentHeight: '',
        galleries: null,

        // Contact form vars
        contactName: '',
        contactEmail: '',
        contactSubject: '',
        contactMessage: '',

    //   grid: new Grid,
        cakes: null,
        isMobile: window.innerWidth < 800 ? true : false,
        extraListing: [
            {id: 1, text: "1 pack of birthday candles", price: 0, tag: "-free"},
            {id: 2, text: "Plastic stick with Happy Birthday wording", price: 0, tag: "-free"},
            {id: 3, text: "Fresh fruits/berries topping", price: 200, tag: "THB"},
            {id: 4, text: "Fruits/berries jelly topping", price: 100, tag: "THB"},
            {id: 5, text: "Chocolate decoration", price: 100, tag: "THB"},
            {id: 6, text: "Edible print", price: 200, tag: "THB"},
            {id: 7, text: "Icing fondant", price: 500, tag: "THB"},
            {id: 8, text: "Fondant figurine", price: 150, tag: "THB/ea"},
        ],
        deliveryAreaList: [
            {id: 1, text: "Ao Nang", price: 200, tag: "THB"},
            {id: 2, text: "Klong Muang", price: 300, tag: "THB"},
            {id: 3, text: "Krabi town", price: 400, tag: "THB"},
            {id: 4, text: "Phuket", price: 1000, tag: "THB"},
        ],
        nameError: '',
        emailError: '',
        subjectError: '',
        messageError: '',
        contactFormSent: false,
    }
  },
  methods: {
      makeOrder() {
          window.location.href = "/#contact"
      },
    sendContactForm() {

        /** Check if all fields are fielled */
        if(this.validateForm() == false) {
            return
        }
        let gCaptcha = document.getElementById('g-recaptcha-response');
        let gCaptchaValue = null;
        if(gCaptcha) {
            gCaptchaValue = gCaptcha.value
        }else{
            gCaptchaValue = '';
        }
        let contactData = {
            contact: {
                name: this.contactName,
                email: this.contactEmail,
                subject: this.contactSubject,
                message: this.contactMessage,
                captcha: gCaptchaValue
            }
        }
        axios.post('/send_contact_form',contactData)
        .then(response => {
            console.log({response})
            if(response.data == 'ok' && response.status == 200){
                this.contactFormSent = true;
            }
        })
        .catch(error => (console.log({error})))
    },

    /**
    Reload page and scrill to contact form
    */
    reloadPage() {
        location.reload();
    },

    clearForm() {
        this.contactName = ''
        this.contactEmail = ''
        this.contactSubject = ''
        this.contactMessage = ''
        this.contactFormSent = false;
    },

    /**
    Reload page instead of reload form
    to initialize google captcha
    */
    sendNewMessage() {
        this.reloadPage();
        // this.clearForm();
    },

    validateForm() {
        let valid = true;
        this.nameError = '';
        this.emailError = '';
        this.messageError = '';
        if(this.validateParam(this.contactName) == false) { this.nameError = "Name is required!"; valid = false;}
        if(this.validateEmail(this.contactEmail) == false) { this.emailError = "Please check you email!"; valid = false;}
        // if(this.validateParam(this.contactName) == false) { this.nameError = "Name is required!"; valid = false;}
        if(this.validateParam(this.contactMessage) == false) { this.messageError = "Please write you message!"; valid = false;}
        return valid;
    },
    validateParam(params) {
        let valid = true; 
        if(params.length <= 0) {
            valid = false;
        }
        return valid;
    },
    validateEmail(value) {
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value);
    },
    openImage(event) {
        console.log("clicked image", event)
        // this.grid.init();
        // Grid.init();
        // console.log("click:", event)
    },
    toTop() {
        this.contentHeight = (window.innerHeight / 2) - 100 + "px";
    },
    getCakes() {
        axios.get('/api/v1/cakes')
            .then((response) => {
                if(response.status == 200) {
                    this.cakes = response.data;
                }
            })
            .catch(error => (console.error({error})))
    }
  },
  mounted() {
      this.toTop();
      this.getCakes();

    // var g = this.$refs.og-grid;
    // console.log({g})
  },
  components: {
    "header-view" : Header,
    Gallery,
  }
}
</script>

<style scoped lang="scss">
.video-player {
    position: relative;
    width: 100%;
    height: 420px;
}
.video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    video {
        /* Make video to at least 100% wide and tall */
        min-width: 100%; 
        min-height: 100%; 
        /* Setting width & height to auto prevents the browser from stretching or squishing the video */
        width: auto;
        height: auto;
        /* Center the video */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%,-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
    .overlay {
        background: url('~images/overlay.png') repeat;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        // flex-direction: column;
        h2 {

            // font-size: 32px;
            // line-height: 45px;
            // padding: 120px 80px 0;
            color: #fff !important;
            font-weight: 400;
            text-align: center;
        }
        h4 {
            // font-size: 22px;
            // line-height: 35px;
            // padding: 120px 40px;
            color: #fff !important;
            font-weight: 400;
            text-align: center;
        }
    }
}

p {
//   font-size: 2em;
//   text-align: center;
}
h2 {
    font-weight: 700;
}
h3 {
    line-height: 80px;
    font-weight: 700;
}
#big_image {
    background: url('~images/big_image.jpg') repeat-y top center fixed;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}
.bg-image {
  background: url('~images/home_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  .light-overlay {
    background: url('~images/overlay.png');
    position: relative;
    width: 100%;
    height: 100%;
    #content {
        position: absolute;
        top: 220px;
        margin-left: auto;
        margin-right: auto;
        left: 1%;
        right: 1%;
        z-index: 1;
        .heart {
            position: relative;
            width: 50px;
            height: 45px;
            margin: 0px auto 0px;
            -webkit-transform-origin: center center;
            -moz-transform-origin: center center;
            -ms-transform-origin: center center;
            -o-transform-origin: center center;
            &:before, &:after {
                position: absolute;
                content: "";
                left: 23.5px;
                top: 0;
                width: 25px;
                height: 40px;
                background: #E34834;
                -moz-border-radius: 50px 50px 0 0;
                border-radius: 50px 50px 0 0;
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: 0 100%;
                -moz-transform-origin: 0 100%;
                -ms-transform-origin: 0 100%;
                -o-transform-origin: 0 100%;
                transform-origin: 0 100%;
            }
            &:after {
                left: 0;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transform-origin: 100% 100%;
                -moz-transform-origin: 100% 100%;
                -ms-transform-origin: 100% 100%;
                -o-transform-origin: 100% 100%;
                transform-origin: 100% 100%;
            }
        }
        .byline {
        margin: 0px auto 20px;
        text-align: center;
        color: #fff;
        letter-spacing: 4px;
        font-size: 16px;
        line-height: 1.2em;
        font-weight: 700;
        text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
        }
        h1 {
            margin: 0 auto !important;
            text-align: center;
            color: #fff !important;
            letter-spacing: 1px !important;
            // font-size: 100px !important;
            line-height: 1em;
            font-weight: 700 !important;
            padding: 0 0 20px 0;
            text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
            text-transform: none;
        }
        .name {
        margin: 0px auto;
        text-align: center;
        color: #fff;
        letter-spacing: 6px;
        font-size: 18px;
        font-weight: 600;
        text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
        }
        #work {
            background: url(/assets/quote-10595e1….jpg) 50% 0% fixed no-repeat;
            background-position: top center;
            -webkit-background-size: cover !important;
            -moz-background-size: cover !important;
            -o-background-size: cover !important;
            background-size: cover !important;
            padding: 0px 0px;
        }
    }
  }
}
.price-list li {
    text-align: center;
    border-bottom: 1px dashed lightgray;
    display:flex;
    justify-content:space-between;
    @apply text-gray-700 text-base py-2;
}

.extra-price-list {
    li {
        border-bottom: 1px dashed lightgray;
        display:flex;
        justify-content:space-between;
    }
}

.delivery-list {
    margin-bottom: 20px;
    li {
        border-bottom:1px dashed lightgray;
        display:flex;
        justify-content:space-between;
    }
}

/** Plus button for gallery */
// .cbp-l-loadMore-button {
//   text-align: center;
//   margin: 30px auto;
// }
// .cbp-l-loadMore-button-link {
//   font-size: 100px;
//   color: #d3d4d5;
// }
.load {
  color: #d3d4d5;
  font-size: 100px;
  &:hover {
    color: #777;
  }
}
.cakes {
    li {
        font-size: 20px;
        line-height: 30px;
        padding: 20px 0 5px 0;
        border-bottom: 1px solid #ccc;
        h3 {
            font-size: 30px;
            line-height: 35px;
        }
        p {
            text-align: left;
            padding: 5px 0;
        }
        .btn {
           padding: 3px 20px; 
           margin-bottom: 5px;
        }
    }
}
.contact-details {
    li {
        display: flex;
        height: 30px;
        align-items: flex-start;
        // justify-content: flex-start;
        color: #444;
        img {
            margin-right: 8px;
        }
    }
}
.social-media {
    li {
        display: inline-flex;
    }
}
</style>
