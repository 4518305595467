<template>
  <div id="appTerms">
    <Header />

    <div class="container first-container mx-auto">
      <div class="flex flex-wrap">
        <div class="px-4">
          <h1 class="text-lg md:text-3xl">Terms and Refund Policy</h1>
          <h3 class="text-lg">This policy explains how you handle returns, refunds, and exchanges.</h3>

          <p class="py-4">
            Although we strive for perfection with every cake order we make, sometimes items
            come up that need to be addressed.
          </p>

          <ol class="py-2 list-decimal list-inside" type="1">
            <li>
              If there is a serious problem with any of our products you MUST let us know
              immediately by telephone. If it is after our regular business hours please send
              us an e-mail. It is vital that you inform us of the problem immediately (same
              day as pickup).
            </li>
            <li>
              The product must be returned SAME DAY OR LATEST THE NEXT DAY. Please bring back
              all of it. Products with only small fractions remaining (for example, 5 or 6
              pieces of a 20 slice cake) will not be considered for a refund. Do not throw any
              of the product away.
            </li>
            <li>
              If both of the above criteria are met the product will be considered for a
              refund. Only legitimate errors on our part will be considered for refund. Issues
              that will NOT be considered for refund include:
              <ul class="list-disc list-inside p-4" type="i">
                <li>
                  If you give no, or only partial, design and decoration instructions during
                  ordering then say you don’t like the design. If you give no instructions then
                  our designers will choose their own design. You have 100% control of your design
                  DURING ORDERING but if you don’t specify we will decorate it according to our
                  designs. This will not be a valid reason for return/refund.
                </li>
                <li>If you dropped the product.</li>
                <li>If the product was improperly stored.</li>
                <li>
                  Choosing a
                  flavor that you don’t like.
                </li>
                <li>Choosing a design that you don’t like.</li>
                <li>
                  Purchasing a
                  larger cake then you needed.
                </li>
              </ul>
            </li>
            <li>
              VERY IMPORTANT! CAKES PICKED UP EARLY, MEANING NOT BEING PICKED UP ON THE SAME
              DAY THAT THEY WILL BE EATEN, WILL NOT BE CONSIDERED FOR REFUND, NO MATTER WHAT
              THE PROBLEM. THERE ARE TOO MANY STORAGE VARIABLES THAT CAN GO WRONG THAT WE
              CANNOT BE HELD RESPONSIBLE FOR.
            </li>
          </ol>

          <div class="py-4">
            <a href="/#contact" class="py-4 btn">Contact to order</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
export default {
  name: "appTerms",
  props: [],
  data() {
    return {};
  },
  components: {
    Header
  }
};
</script>

<style scoped lang="scss">
.first-container {
  padding-top: 140px;
  font-size: 18px;
  h1 {
    letter-spacing: 0px !important;
    // line-height: 45px;
    padding-bottom: 15px;
    text-transform: capitalize;
    font-weight: 800;
  }
  h3 {
    letter-spacing: 0px !important;
    // line-height: 35px;
    padding-bottom: 15px;
    text-transform: capitalize;
  }
}
ol {
  list-style-type: decimal !important;
  li {
    list-style-type: decimal;
    margin: 10px auto;
  }
}
.right-column {
  padding-left: 15px;
}
</style>