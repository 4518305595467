<template>
  <div id="app" class="gal-container">
    <ul ref="list">
      <li
        v-for="(item,index) in this.galleries"
        :data-id="item.id"
        :data-index="index"
        :data-image-url="item.image_url"
        :data-name="item.name"
        :data-tag-list="item.tag_list"
        :data-orig-id="item.orig_id"
        :data-text="item.text"
        :key="item.id"
        :class="{wrapper: item.id == 99999}"
      >
        <a
          v-if="item.id !== 99999"
          @click.prevent="openImage(item.id)"
          :data-dup-id="item.id"
          :class="{imageBlock: item.id == 99999}"
          target="_blank"
          :href="galleryHref(item.orig_id)"
        >
          <img :src="item.image_url" :alt="item.name" width="300" />
          <div v-if="item.id == 99999" class="wrapper-description">
            <h3 v-text="item.name"></h3>
            <p v-if="item.text != item.name" v-text="item.text"></p>
            <p v-text="showTags(item.tag_list)"></p>
          </div>
        </a>
        <a
          v-else
          :data-dup-id="item.id"
          :class="{imageBlock: item.id == 99999}"
          target="_blank"
          :href="galleryHref(item.orig_id)"
        >
          <img :src="item.image_url" :alt="item.name" width="300" />
          <div v-if="item.id == 99999" class="wrapper-description">
            <h3 v-text="item.name"></h3>
            <p v-if="item.text != item.name" v-text="item.text"></p>
            <p v-text="showTags(item.tag_list)"></p>
          </div>
        </a>
      </li>
      <!-- <div ref="project_wrapper" id="project-wrapper"></div> -->
    </ul>
    <!--LOAD MORE BUTTON-->
    <div class="cbp-l-loadMore-button">
      <a href="#" @click.prevent="getImages()" class="cbp-l-loadMore-button-link">
        <i
          v-if="!isMobile"
          class="fa fa-plus-circle wow load pulse"
          data-wow-iteration="infinite"
          data-toggle="tooltip"
          data-placement="bottom"
          id="bottom"
          title="Load More Cakes"
          data-original-title="load more"
          style="visibility: hidden; animation-iteration-count: infinite; animation-name: none;"
        ></i>
        <i
          v-if="isMobile"
          class="fa fa-plus-circle"
          data-wow-iteration="infinite"
          data-toggle="tooltip"
          data-placement="bottom"
          id="bottom"
          title="Load More Cakes"
          data-original-title="load more"
          style="visibility: visible;"
        ></i>
      </a>
    </div>
    <!--//LOAD MORE BUTTON-->
  </div>
</template>

    <script>
import axios from "axios";
export default {
  id: "#app",
  props: ["isMobile"],
  data() {
    return {
      message: "this is gallry",
      from: 0,
      take: 12,
      data_image_count: 12,
      contentHeight: "",
      galleries: null,
      // windowWidth: this.$refs.list.clientWidth,
      itemsWidth: 0,
      selectedImage: 0

      /** Settings */
      //totalItems: this.data_image_count,
      //listItems: //galleries
    };
  },
  computed: {
    opened() {
      return this.message;
      // console.log("opended");
    }
  },
  mounted() {
    this.getImages();
    // this.numberImages();
  },
  updated() {
    let windowWidth = this.$refs.list.clientWidth;
    let ul = document.querySelector("ul");
    let width = ul.children[0].clientWidth;
    // console.log('item width', windowWidth / width)
    this.itemsWidth = windowWidth / width;
  },

  methods: {
    showTags(list) {
      return list.split(",").join(" #");
    },
    numberImages() {
      console.log("width:", this.$refs.list.clientWidth);
    },
    galleryHref(id) {
      if (id !== 99999) {
        return "/gallery/" + id;
      } else {
        return "#";
      }
    },

    openImage(id) {
      let rIndex = this.galleries.map(item => item.id).indexOf(99999);
      if (rIndex > 0) {
        this.galleries.splice(rIndex, 1);
      }
      if (id == this.selectedImage) {
        this.selectedImage = 0;
        return;
      }
      this.selectedImage = id;
      let tile = document.querySelector('li[data-id="' + id + '"]');
      // let rowItem = this.getModulo(id);
      let rowItem = this.getModuloIndex(tile.getAttribute("data-index"));
      const arr = { "0": "3", "1": "2", "2": "1", "3": "0" };
      let selectedTile = tile.getAttribute("data-id");
      let selectedIndex = tile.getAttribute("data-index");
      // let firstItem = selectedTile - rowItem;
      let firstItem = selectedIndex - rowItem;
      let lastItem = firstItem + this.itemsWidth;
      // var res = Number(selectedTile) + Number(arr[rowItem]);
      // let lastItem = res;

      // console.log("=================");
      // console.log("selected index", selectedIndex % 4);
      // console.log("selected tile", Number(selectedTile));
      // console.log("arr: ", Number(arr[selectedIndex % 4]));
      // console.log("last: ", res);
      // console.log("selected Tile: ", selectedTile);
      // console.log("first Item: ", firstItem);
      // console.log("last Item: ", lastItem);
      // console.log("row Item: ", rowItem);

      let imageUrl = tile.getAttribute("data-image-url");
      let name = tile.getAttribute("data-name");
      let tagList = tile.getAttribute("data-tag-list");
      let text = tile.getAttribute("data-text");
      let data = {
        id: 99999,
        orig_id: tile.getAttribute("data-orig-id"),
        image_url: imageUrl,
        name: name,
        tag_list: tagList,
        text: text
      };
      //"<li class='body' style='background: red;'><a href='#hello'>hello</a></li>"

      //   console.log("lastItem: ", lastItem);
      console.log("ID: ", id);
      this.galleries.splice(lastItem, 0, data);
    },
    getModulo(num) {
      //   return (num - 1) % this.itemsWidth;
      return num % this.itemsWidth;
    },
    getModuloIndex(num) {
      return num % this.itemsWidth;
    },
    getImages() {
      axios("/api/v1/galleries?from=" + this.from + "&take=" + this.take)
        .then(response => {
          if (this.from == 0) {
            this.galleries = response.data;
          } else {
            // console.log("from:", this.from);
            for (var i = 0; i < response.data.length; i++) {
              this.galleries.push(response.data[i]); // push more galleries to the array
              // console.log("id:", response.data[i].id);
            }
          }
          /**
           * Update number from (offset) + number of take
           *  0 + 12 = 12; then 12 + 12 = 24
           */
          this.from += this.take;
          this.data_image_count = this.from;
        })
        .catch(error => console.log({ error }));
    }
  }
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.gal-container {
  max-width: 1200px;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  width: 100%;
  background: white;
}
.wrapper-description {
  font-size: 120%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 700;
    font-size: 140%;
    line-height: 140%;
  }
  p {
    margin-top: 10px;
  }
}
.imageBlock {
  display: flex;
  // justify-content: space-between;
}
@media only screen and (max-width: 800px) {
  .imageBlock {
    display: flex;
    flex-direction: column;
    height: auto;
    img {
      display: none;
    }
  }
}

ul {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  // font-size: 0;
  li {
    display: inline-block;
    width: 25%;
    vertical-align: middle;
    overflow: hidden;
    // font-size: 0;
    // border-bottom: 1px solid #000;
    a {
      img {
        margin: 0 auto;
      }
    }
  }
}
@media only screen and (max-width: 959px) {
  ul li {
    width: 33.333333333333%;
  }
}

@media only screen and (max-width: 800px) {
  ul li {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  ul li {
    width: 100%;
  }
}
ul li + li {
  // border-left: 1px solid #000;
}
ul li a {
  display: block;
  width: 100%;
  height: 300px;
  padding: 5px;
  // background-color: #999;
}
ul li div {
  // position: absolute;
  top: auto;
  left: 0;
  height: 500px;
  // background-color: #666;
  width: 100%;
}
#project-wrapper {
  height: 200px;
  width: 100%;
  background-color: #000;
  display: none;
}
</style>